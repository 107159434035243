<template>
    <div class="create-succeed">
        <img src="@/assets/images/CreatePaperSucceed.png" alt="" />
        <div class="hint">创建成功</div>
        <el-button class="blue-button" @click="backList">返回列表</el-button>
    </div>
</template>

<script>
export default {
    name: "CreateSucceed",
    data() {
        return {};
    },
    components: {},
    methods: {
        //创建成功后返回列表页
        backList() {
            this.$router.replace("/admin/paperManage");
        },
    },
};
</script>

<style scoped lang="scss">
.create-succeed {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 5%;
    font-size: 23px;
    .hint {
        margin: 30px 0;
    }
    ::v-deep .blue-button {
        background-color: #1122d8;
        border-color: #1122d8;
        color: #fff;
        font-size: 14px;
    }
}
</style>
