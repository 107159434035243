<template>
    <div class="path">
        <span class="item" @click="back">理论考试管理 </span>
        >
        <span class="item" @click="back">试卷管理 </span>
        >
        <span class="current"> {{ currentPath }}</span>
    </div>
</template>

<script>
export default {
    name: "HeadPath",
    data() {
        return {};
    },
    props: {
        currentPath: {
            type: String,
            default: "",
        },
    },
    components: {},
    methods: {
        back() {
            this.$router.back();
        },
    },
};
</script>

<style scoped lang="scss">
.path {
    margin-bottom: 40px;
    color: #999;
    .item {
        transition: all 0.3s;
        cursor: pointer;
    }
    .item:hover {
        color: #1122d8;
    }
    .current {
        color: #343441;
    }
}
</style>
